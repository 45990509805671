<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New
        Cold Chain Equipment to Store Vaccines at Appropriate Temperatures
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="../../assets/images/covid oon deep oce.jpg"
            alt=""
            width="100%"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt
            New Cold Chain Equipment to Store Vaccines at Appropriate
            Temperatures
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: JUNE 13, 2021</p>
            </span>
          </div>
          <p class="font-weight-bold">
            Current records show that as of May 2021, Nigeria recorded 166,098
            COVID-19 cases and 2071 deaths. Also, about 156,528 individuals were
            reported to have recovered from the coronavirus. Vaccines play an
            important role in reducing the death toll in epidemic, pandemics and
            other degrees of disease outbreaks. The mortality rate of persons
            infected with the coronavirus has dropped since the advent of the
            COVID-19 vaccine. Health workers have adopted new cold chain
            technologies and temperature monitoring devices to maintain the
            supply cold chain of COVID-19 vaccines.
          </p>
          <p class="">
            In March 2021, 3.94 million doses of COVID-19 vaccines were
            transported to Nigeria from Mumbai. Unlike regular mass immunization
            campaigns in Nigeria, the COVID-19 vaccine has to be transported to
            millions of Nigerians living in rural and urban areas within a
            limited period. Storage and transportation of the COVID-19 vaccines
            is a key challenge in the healthcare industry of developing
            countries. Majorly, this challenge is due to the paucity of adequate
            cold chain infrastructures needed to sustain and monitor the
            temperature of COVID-19 vaccines.
          </p>
          <p class="">
            To retain the potency of the millions of doses of COVID-19 vaccines
            allotted to developing countries in Africa, new temperature
            management systems have to be incorporated into the healthcare
            industry of these countries. A redesign of the vaccine cold chain
            management system in these countries is a requisite to curtail the
            loss of medical items and prevent the wastage of COVID-19 vaccines.
            Currently, cold chain equipment like MOTE Cold Boxes has been
            developed to tackle the problem of inadequate cold chain systems in
            developing countries.
          </p>
          <p>
            Currently, about 1,557,051 COVID-19 vaccine doses have been
            administered, leaving about 2.93 million COVID-19 vaccine doses yet
            to be administered. Poor management of the COVID-19 vaccines might
            jeopardize the efforts of the global healthcare industry in
            curtailing the spread of the coronavirus and reducing the mortality
            rate of persons infected with the virus.
          </p>
          <p>
            Thousands of persons in developing countries are yet to be immunized
            against the coronavirus because they reside in areas that are
            difficult to access. To tackle this challenge, some health
            organizations have adopted the use of MOTE Cold Box to store
            COVID-19 vaccines. The newly developed MOTE Cold Boxes technology
            has advanced features that promote sustainable and uninterrupted
            supply cold chain management for COVID-19 vaccines. A
            <a
              href="https://edition.cnn.com/2021/01/14/africa/gallery/coronavirus-innovations-africa-spc-intl/index.html"
              target="_blank"
              style="text-decoration:underline !important; color:#28a84a"
              >publication by the Cable News Network (CNN) on innovations that
              are helping to combat COVID-19 across Africa</a
            >
            elucidated the role MOTE played in the transportation of COVID-19
            vaccines in the thick of the COVID-19 pandemic.
          </p>

          <figure class="pt-8">
            <img
              src="../../assets/images/gricdggg.jpg"
              alt=""
              width="100%"
              class=""
            />
          </figure>
          <figure class="py-8">
            <img
              src="../../assets/images/ffff.jpg"
              alt=""
              width="100%"
              class=""
            />
            <figcaption
              class=""
              style="font-size: 0.8125rem;font-style: italic; color:#999"
            >
              MOTE-50 Cold Box cold chain equipment played a vital role in the
              transportation of COVID-19 vaccines to remote areas in Nigeria
              during the COVID-19 pandemic.
            </figcaption>
          </figure>

          <p>
            MOTE Cold Box, also called MOTE-50, has a 50- litre storage
            capacity. MOTE Cold Boxes are reusable and can be used to track the
            location of vaccines that are being conveyed to the point of use.
            The challenge of transporting vaccines for mass vaccination in very
            remote areas has been resolved with the procurement of MOTE Cold
            Boxes to track and sustain the temperature of vaccines. These
            devices are portable and can be carried on a bicycle, motorcycle or
            as a backpack.
          </p>
          <p>
            MOTE-50 cold chain equipment has a built-in temperature monitor that
            allows it to serve as a temperature monitoring device and a cold
            box. Health workers can keep records of the temperature and humidity
            of COVID-19 vaccines stored using the MOTE-50 cold chain equipment
            from anywhere in the world (without physical access to the device)
            during transportation and storage. MOTE Cold Box creates a regulated
            environment to sustain the potency of stored vaccines at the site of
            production, during transportation and at the point of vaccination.
          </p>
          <p>
            The MOTE 50 cold chain equipment holds a prominent place among some
            of the latest technologies rewriting the supply cold chain story in
            the Nigerian healthcare system
          </p>

          <figure class="py-8 ">
            <img
              src="../../assets/images/Chambre-froide-portative-Gricd-fridg-DR-1024x576.jpg"
              alt=""
              width="100%"
              class=""
            />
            <figcaption
              class=""
              style="font-size: 0.8125rem;font-style: italic; color:#999"
            >
              MOTE-50 cold chain equipment has a built-in temperature monitor
              that allows it to serve as a data logger and cold storage
              equipment.
            </figcaption>
          </figure>

          <p>
            Schedule a free demo of the MOTE temperature monitoring device
            <router-link
              to="/contact/demo-request"
              style="color:#28a84a; text-decoration:underline !important"
            >
              here.
            </router-link>
          </p>
          <p>
            You can visit
            <router-link
              to="/"
              style="color:#28a84a; text-decoration:underline !important"
              >www.gricd.com</router-link
            >
            to find out more about Gricd Integrated Services Limited. Also,
            visit
            <router-link
              to="/cold-chain-equipments"
              style="color:#28a84a; text-decoration:underline !important"
              >www.gricd.com/cold-chain-equipments</router-link
            >
            to find out more about other cold chain equipment developed by Gricd
            Integrated Services Limited.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from '../../assets/images/baby vaccination.jpg';
import image5 from '../../assets/images/covid oon deep oce.jpg';
import image6 from '../../assets/images/covid19-vaccine-waste.jpg';
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name:
                  'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
                item:
                  'https://gricd.com/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
p span {
  text-decoration: underline;
  color: #28a84a;
}
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
</style>
